// eslint-disable-next-line import/prefer-default-export
export const formatDate = strData => {
  if (!strData) {
    return ''
  }
  const date = new Date(strData)
  const [dt, tm] = date.toLocaleDateString('ru', {
    day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit',
  }).split(' ')
  return `${dt.split('-').reverse().join('.')} ${tm}`
}
